<template>
  <div class="simple-table">
    <b-table :striped="striped" :hover="hover" :items="items" borderless="true"></b-table>
  </div>
</template>
<script>
export default {
  props: {
    items: Object,
    striped: Boolean,
    hover: Boolean
  }
}
</script>
<style lang="scss">
@import '@/styles/settings.scss';
.simple-table {
  margin-bottom: 22px;
  overflow-x: auto;
  &::-webkit-scrollbar {
    padding-top: 8px;
    width: 24px;
    height: 24px;
  }
  &::-webkit-scrollbar-thumb {
    background: #d0d0d0;
    border-radius: 12px;
  }
  &::-webkit-scrollbar-track {
    background: #fff;
    margin: 8px;
  }
  table {
    &.table {
      margin-bottom: 8px;
    }
    th,
    td {
      padding: 4px;
    }
    @include breakpoint(sm) {
      th {
        white-space: nowrap;
        padding-left: 25px !important;
        &:first-of-type {
          padding-left: 4px !important;
        }
      }
    }
  }
}
</style>
